<template>
  <div class="PublicPaap" style="padding: 0 1.75rem 0;background-color: #fff;max-height: 90rem;overflow-y: scroll;">
    <PaapViewer v-if="paapData" :paap="paapData" />
    <div v-else style="text-align: center; padding: 4rem 0">
      <i class="fas fa-sync fa-spin fa-3x" style=""></i>
    </div>
  </div>
</template>

<script>
import { PAAP } from '@/api.js'
import PaapViewer from "@/components/PaapViewer";

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  components: {
    PaapViewer,
  },
  data() {
    return {
      paapData: null,
    };
  },
  computed: {
    paapToken() {
      return this.$route.params.paap_token;
    },
  },
  methods: {
    getPaapFromToken(token) {
      token = token || this.paapToken;
      const error = msg => {
        this.$toastr.e(msg || 'PAAP nu a fost găsit.')
        
        this.goToHomePage()
      }

      PAAP.getFromToken(token).then(res => {
        if(this.isObject(res?.data?.result)) {
          this.paapData = res.data.result
        } else {
          error()
        }
      }).catch(error)
    },
  },
  created() {
    this.getPaapFromToken();
  },
};
</script>

<style>
.PublicPaap .total-paap_result {
  margin-top: 0;
  padding-top: 10px;
}
</style>